import React from 'react';
import Layout from '../components/layout';
import Scrimmage from '../components/scrimmage';
import SEO from '../components/seo';

const CompetePage = () => (
  <Layout>
    <SEO
      title="Compete Against Another Bot | Test your Code Championship bot against your opponent"
      description="Code Championship allows you to test your bot head to head against your friends for free. May the best code win!"
      keywords={['compete', 'friend', 'test', 'opponent']}
    />
    <header>
      <h1>Compete</h1>
      <div className="stripe-hr thin" />
    </header>
    <Scrimmage allPlayers={[]} />
  </Layout>
);

export default CompetePage;
